<template>
    <div class='container pv20'>
        <p v-if="noMore"
           class="center">没有更多</p>
        <p v-else
           class="center">
            <i class="el-icon-loading"></i>
            <span>{{loadText}}</span>
        </p>

    </div>
</template>
<script>
export default {
  name: "LoadMore",
  props: {
    noMore: {
      type: Boolean,
      required: true
    },
    loadText: {
      type: String,
      default: "房源加载中..."
    }
  },
  data() {
    return {
      status: "loading"
    };
  },
  methods: {},
  mounted() {}
};
</script>
<style scoped>
</style>