<template>
  <div class="container">
    <div class="content">
      <router-link to="/kandian" class="flex s_center">
        <img width="35" height="35" :src="greenLogo" alt="" />
        <span class="green pl5 size30">{{companyConfig.firstName}}看点</span>
      </router-link>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex"
export default {
  name: "highlightHeader",
  computed: {
    ...mapState(["companyConfig"]),
    greenLogo() {
      return  this.companyConfig.greenLogo
    }
  },
};
</script>
<style lang='less' scoped>
.container {
  background-color: #f5f5f6;
  padding: 26px 0;
}
</style>