<template>
  <div>
    <Header />
    <div class="content">
      <ul>
        <highlight-item v-for="article in articles" :key="article.guid" :article="article" />
        <load-more :no-more="noMore" load-text="看点加载中···" />
      </ul>
    </div>
  </div>
</template>
<script>
import Header from "./component/Header.vue";
import HighlightItem from "./component/HighlightItem";
import LoadMore from "@/components/load-more";
import Constant from "@/common/Constant";
import tool from "@/utils/tool";
import houseServer from "@/services/houseServer.js";
import {  getItem } from '../../utils/storage'
let that;
export default {
  name: "highlight",
  components: {
    Header,
    HighlightItem,
    LoadMore
  },
  data() {
    return {
      params: {
        page: 1,
        itemsPerPage: 10,
        categoryGuid: "580f3e4deefc451d9fee553c1af5a74e",
        companyGuid: getItem(Constant.CITY_COMPANY).cityCompanyGuid,
        pcOrMobile:true
      },
      totalPage: 1,
      articles: [],
      noMore: false,
      loading: false
    };
  },
  methods: {
    async loadArticle() {
      if (this.loading || this.noMore) return;
      this.loading = true;

      const [
        err,
        {
          data: { pages, records }
        }
      ] = await tool.to(houseServer.customerArticle(this.params));
      if (err) {
        this.loading = false;
        this.noMore = false;
        console.log("customerArticle :>>", err);
      }
      this.loading = false;
      if (this.params.page === 1) {
        this.totalPage = pages;
      }
      this.articles = [...this.articles, ...records];
      if (this.params.page >= this.totalPage) {
        this.noMore = true;
      }
      //   console.log("data :>>", JSON.stringify(records));
    },
    handleScroll: tool.throttle(() => {
      const html = document.documentElement,
        body = document.body;
      const scrollTop = window.pageYOffset || body.scrollTop || html.scrollTop;
      const clientHeight =
        window.innerHeight || body.clientHeight || html.clientHeight;
      const scrollheight = body.scrollHeight || html.scrollHeight;

      if (scrollTop + clientHeight > scrollheight - 50) {
        that.params.page++;
        that.loadArticle();
      }
    }, 400)
  },
  created() {
    this.loadArticle();
  },
  mounted() {
    that = this;
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style lang='less' scoped>
</style>