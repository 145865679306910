<template>
  <li class="flex kandian-item">
    <router-link :to="path" class="card-left" target="_blank">
      <img :src="tempImgs" class="all" />
    </router-link>
    <div class="card-right column sb_start">
      <router-link class="black size20 bold" :to="path" target="_blank">{{article.title}}</router-link>
      <span style="color:#9399a5">{{article.createTime}}</span>
    </div>
  </li>
</template>
<script>
import Constant from "@/common/Constant";
export default {
  name: "highlightItem",
  props: {
    article: Object
  },
  computed: {
    path() {
      return `/detail/${this.article.guid}`;
    },
    tempImgs() {
      const _tmpImages = this.article.tmpImages;
      const imgs = _tmpImages.split(",");
      let ret = Constant.DEFAULT_HOUSE;
      if (!_tmpImages) return ret;
      for (let i = 0; i < imgs.length; i++) {
        if (!i && imgs[i]) {
          ret = imgs[i];
        } else {
          if (ret) break;
          if (!ret) {
            ret = imgs[i];
          }
        }
      }
      return ret;
    }
  }
};
</script>
<style lang='less' scoped>
.kandian-item {
  padding: 30px 0;
  border-bottom: 1px solid #e4e6f0;
  width: 754px;
  min-height: 210px;
  box-sizing: border-box;
  .card-left {
    width: 200px;
    height: 150px;
    margin: 0 24px 0 0;
  }
  .card-right {
    a:hover {
      color: #00ae66;
    }
  }
}
</style>